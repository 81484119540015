.header-month input {
  border-bottom: none;
  text-align: center;
  max-width: 190px;
  cursor: pointer;
   font-family: 'AktivGrotesk';
  font-weight: 700;
}

.table-cell {
  padding: 8px;
  border: 1px solid #DEDEDE;
  border-radius: 12px;
  height: 100%;
}
.table-cell a {
  display: flex;
  justify-content: space-between;
}
.table-cell:hover .add-symbol .first-icon {
  opacity: 1;
}

.second-icon {
  display: none;
}

.first-icon:hover {
  display: none;
}

.first-icon:hover+.second-icon {
  display: block;
  opacity: 1;
  cursor: pointer;
}

.table-cell-hours {
  float: right;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 2px 8px;
  border-radius: 4px;
  height: 26px;
}

.add-symbol {
  float: right;
  padding: 5px 2px 0px 0px;
}

.add-symbol svg {
  height: 28px;
  width: 28px;
  margin-top: calc(50% - 10px);
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.3s ease;
}