html {
  min-width: 1290px;
  position: relative;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

.asteriskField {
  display: none;
}

form .alert ul li {
  list-style: none;
}

form .alert ul {
  padding: 0;
  margin: 0;
}

.username {
  width: 216px;
  height: 22px;
  font-family: AktivGrotesk;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #5d6877;
  margin-left: 66px;
}

.small-text {
  font-family: AktivGrotesk;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
.bold {
  font-weight: 700;
}

.instruction {
  width: 1080px;
  height: 43px;
  font-family: AktivGrotesk;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #7f7f7f;
}

.text-style-1 {
  font-weight: 600;
  color: #252b34;
}

.PROJECT {
  width: 100%;
  height: 26px;
  font-family: AktivGrotesk;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: normal;
}

.active {
  color: #f58220;
}

.LEAVE {
  width: 100%;
  height: 26px;
  font-family: AktivGrotesk;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: normal;
}

.active_div {
  width: 100%;
  text-align: left;
  border-bottom: 4px solid #f58220;
}

._div {
  width: 100%;
  text-align: left;
  border-bottom: 2px solid #cccccc;
}

.navbar-nav {
  flex-direction: row;
  justify-content: flex-end;
}

.left {
  float: left;
}
.right {
  float: right;
}

.col {
  width: 20px;
}
.calen li {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
  border: 1px solid transparent;
  font-family: AktivGrotesk;
  font-weight: 600;
  font-size: 12px;
  max-height: 30px;
  cursor: pointer;
}

.calen li:hover {
  color: orange;
  text-decoration: none;
}

table {
  table-layout: fixed;
  width: 100%;
  height: 100%;
}

.calendar tr,
.calendar td {
  border: -1px black;
}

.calendar th {
  padding: 8px 4px 12px 4px;
  text-align: center;
  text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    border: none;
    color: #000;
    background: transparent;
}

table.calendar td {
  width: 100%;
  height: 128px;
}

.month {
  font-size: 25px;
}

.date {
  font-size: 20px;
  font-family: AktivGrotesk;
}
.date1 {
  font-size: 20px;
  color: black;
  font-weight: 600;
}

.selected-date {
  font-size: 20px;
  color: orange;
}

.default-date {
  font-size: 20px;
}

.hours {
  padding-left: 20%;
  font-size: 14px;
  font-family: AktivGrotesk;
}

ul {
  height: 100%;
  padding: 0px;
  list-style-type: none;
  /* color: red; */
}

table {
  width: 100%;
  border-collapse: collapse;
}

td,
th {
  padding: 4px;
  text-align: left;
}

div.scroll {
  width: 500px;
  overflow-y: scroll;
  height: 35px;
}
a {
  color: #333;
  font-family: AktivGrotesk;
  cursor: pointer;
}
a:hover {
  color: orange;
  text-decoration: none;
}
.btn-primary {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background-color: #f58220;
  border-color: white;
}

.event_list {
  display: inline-block;
  height: 78px;
  max-height: 78px;
  width: calc(100% - 30px);
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
}

table.calendar {
  width: 100%;
  margin: auto;
  font-size: 9px;
  height: 450px;
  background-color: #ffffff;
  table-layout: fixed;
  vertical-align: top;
  text-align: left;
  padding: 5px;
}

/* #leftside {
  position: absolute;
  left: 0;
  width: 1200px;
  margin-top: 28px;
}
#rightside {
  position: absolute;
  right: 0;
  width: 394px;
}
*/
.navbar-nav {
  flex-direction: row;
}
.show-desktop {
  display: none;
}
.show-mobile{
  display: block
}
.page-container {
    max-width: 1350px;
    margin-left: auto;
    margin-right: auto;
}
.container {
  margin-top: 80px;
}
.ts-header {
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background: #ffffff;
}

@media only screen and (min-width: 600px) {
  .show-desktop {
    display: block;
  }
  .show-mobile {
    display: none;
  }
}

@media only screen and (min-width: 1300px) {
  .col-9 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 70%;
  }

  .row {
    max-width: 1350px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .username {
    margin-left: 55px;
  }
  #leftside {
    position: initial;

    width: auto;
  }
  #rightside {
    position: initial;

    width: 380px;
  }
  table.calendar {
    width: 100%;
  }
  .month_name {
    margin-left: 0px;
  }
}

.left_arrow {
  font-size: 25px;
  color: #5f6368;
  fill: #5f6368;
  opacity: 1;
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.month_font {
  font-family: AktivGrotesk;
  font-weight: 500;
  letter-spacing: 0;
  font-size: 18px;
  line-height: 28px;
  color: #3c4043;
  opacity: 1;
  padding: 5px;
  display: inline-block;
  min-width: 160px;
  text-align: center;
}

.holiday {
    float: right;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    padding: 2px 8px;
    border-radius: 4px;
    height: 26px;
    background-color: #DEDEDE;
}
.medium-text {
  font-size: 14px;
  font-family: AktivGrotesk;
  font-weight: 600;
}
.show-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: scroll;
}
@media (min-width: 200px) {
  #deletemodel .modal-dialog {
    max-width: 453px;
    margin: 1.75rem auto;
  }

  #memberModal .modal-dialog {
    max-width: 396px;
    margin: 1.75rem auto;
  }
}

.nested-links {
  border: 5px solid #000;
  display: inline-block;
  position: relative;
  width: 10px;
  height: 10px;
}

.nested-links__first,
.nested-links__second {
  position: absolute;
  text-decoration: none;
}

.nested-links__first {
  background: #fff;
  color: #000;
  padding: 50px;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.nested-links__second {
  background: #aed9e5;
  color: #00f;
  padding: 20px;

  top: auto;
  left: 50px;
  right: 50px;
  bottom: 50px;
}

.nested-links__first:hover,
.nested-links__second:hover {
  background: #ffc;
  text-decoration: underline;
}
