.header-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 85px;
  margin-right: 100px;
  align-items: center;
  min-height: 72px;
  width: 100%;
  max-width: 1350px;
}

.header {
  font-family: 'AktivGrotesk';
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-transform: uppercase;
  color: #f58220;
  text-align: left;
  display: inline-block;
  margin-left: 10px;
  padding-left: 12px;
  border-left: 2px solid #495678;
}

.nav-item-container {
  display: flex;
  justify-content: flex-end;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.dropdown-toggle {
  white-space: nowrap;
}

.nav-link.dropdown-toggle {
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 3px;
  color: #333;
  font-family: 'AktivGrotesk';
  font-weight: 600;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-menu[x-placement^='bottom'],
.dropdown-menu[x-placement^='left'],
.dropdown-menu[x-placement^='right'],
.dropdown-menu[x-placement^='top'] {
  right: auto;
  bottom: auto;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

button:focus {
  outline: none;
}

