.header-3 {
  font-family: AktivGrotesk;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3;
}
.yy {
  width: 180px;
  height: 26px;
  font-family: AktivGrotesk;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  /* line-height: 1.86; */
  letter-spacing: normal;
  color: #cccccc;
}
input[type='date'] {
  width: 100%;
  position: relative;
}
