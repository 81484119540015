.not-found {
  font-size: 44px;
  font-family: AktivGrotesk;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}
