.select2 {
  height: 26px;
  font-family: AktivGrotesk;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  /* line-height: 1.63; */
  letter-spacing: normal;
  color: #252b34;
}
/* .select2 > div > div {
  max-height: 50px;
}
.select2 > div > div:first-child > div {
  margin: 0;
  padding: 0;
} */
#react-select-35-listbox {
  border: 1px solid black;
  border-radius: 0%;
}
