input {
  border: 0px solid;
  border-bottom: 1px solid #cccccc;
}

select,
option {
  border: 0px solid;
  border-bottom: 1px solid #cccccc;
  background: white;
  width: 100%;
}

.jinja-disabled {
  pointer-events: none;
  opacity: 0.5;
  background: #ccc;
}


.card {
  padding: 5px 5px 5px 5px;
  margin-left: 30px;
  position: absolute;
  width: 319px;
  margin-left: 16px;
  min-height: 500px;
}

.button1 {
  border-radius: 4px;
  background-color: #f58220;
  color: white;
  border: 1px solid transparent;
}

.button1:hover {
  background-color: #d06b15;
  color: white;
}

input[type='date'] {
  width: 100%;
  position: relative;
}

input[type='number'] {
  width: 100%;
  position: relative;
}

#id_description {
  overflow: hidden;
  resize: vertical;
  width: 100%;
}

input[type='date']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

.col-9 {
  max-width: 72% !important;
}

.col-3 {
  max-width: 28% !important;
}

.margin_bottom_12 {
  margin-bottom: 12px;
  width: 100%;
    padding: 0 !important;
}

.center-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.entry-label {
  /* height: 40px; */
  min-width: 62px;
  overflow-wrap: break-word;
  max-width: 52px;
  text-align: left;
  margin-top: 20px;
  font-family: 'AktivGrotesk';
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

.entry-input {
  width: 100%;
  font-size: 16px;
  font-family: 'AktivGrotesk';
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.86;
  letter-spacing: normal;
}
.select-full-width {
  margin-top: 14px;
  width: 100%;
}
.entry-input input,
.entry-input select {
  height: 50px;
  border: 1px solid #DEDEDE;
  padding: 8px;
  border-radius: 8px;
}

.field-active {
  color: #262626;
}
.field-empty {
  color: #8e8e8e;
}

.entry-input textarea {
  order: 1px solid #DEDEDE;
  padding: 8px;
  border-radius: 8px;
  margin-top: 35px;
  width: 100%;
}

select {

  /* styling */
  background-color: white;
  border: thin solid blue;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */

  margin: 0;      
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

select.minimal {
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right,transparent 50%, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}

select.minimal:focus {
  background-image:
    linear-gradient(45deg, green 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, green 50%),
    linear-gradient(to right, transparent 50%, #ccc);
  background-position:
    calc(100% - 15px) 1em,
    calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
  border-color: green;
  outline: 0;
}


select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}


/* Modifying select components styling */

.select2-selection__rendered {
  font-family: 'AktivGrotesk';
}
.select2-results__options {
  font-size: 13px;
  font-family: 'AktivGrotesk';
}

.hrs {
  width: 180px;
  height: 26px;
  font-family: 'AktivGrotesk';
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.86;
  letter-spacing: normal;
  color: #cccccc;
}

.Rectangle {
  width: 14px;
  height: 14px;
}

.Half-Day {
  width: 180px;
  height: 26px;
  font-family: 'AktivGrotesk';
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #252b34;
}

.Sick-Leave {
  width: 180px;
  height: 26px;
  font-family: 'AktivGrotesk';
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #252b34;
  justify-content: space-between;
}

.radio-label {
  width: 100%;
  font-family: 'AktivGrotesk';
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #252b34;
  justify-content: space-between;
}
.radio-label input {
  margin-right: 8px;
}
.project-tag {
  display: inline-block;
  padding: 4px 12px;
  margin-right: 8px;
  margin-top: 8px;
  background-color: #DEDEDE;
  font-family: 'AktivGrotesk';
  font-size: 12px;
  border-radius: 6px;
  cursor: pointer;
}

.not-listed-link {
  margin-top: 4px;
  margin-left: 4px;
   text-decoration: underline;
  color: #d06b15;
  cursor: pointer;
}

 